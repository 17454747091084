#news {
  margin-top: 3em;
  iframe#twitter-widget-0 {
    width: 520px;
  }
  h2 {
    margin-bottom: 30px;
  }
  .newsitem {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 4px;
    margin: 0px 10px 10px 10px;
    .button {
      text-align: right;
    }
  }
}

/* #Tablet (Portrait) - Design for a width of 768px */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  #news iframe#twitter-widget-0, #news iframe {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  #news {
    .col-md-4 {
      &:nth-child(3) {
        text-align: center;
      }
    }
  }
}