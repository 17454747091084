@font-face {
  font-family: 'WibeeRegular';
  src: url('../fonts/berthold-light-webfont.eot');
  src: url('../fonts/berthold-light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/berthold-light-webfont.woff') format('woff'),
  url('../fonts/berthold-light-webfont.ttf') format('truetype'),
  url('../fonts/berthold-light-webfont.svg#RobotoRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WibeeBold';
  src: url('../fonts/berthold-medium-webfont.eot');
  src: url('../fonts/berthold-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/berthold-medium-webfont.woff') format('woff'),
  url('../fonts/berthold-medium-webfont.ttf') format('truetype'),
  url('../fonts/berthold-medium-webfont.svg#RobotoBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WibeeThin';
  src: url('../fonts/wibee-thin-webfont.eot');
  src: url('../fonts/wibee-thin-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/wibee-thin-webfont.woff') format('woff'),
  url('../fonts/wibee-thin-webfont.ttf') format('truetype'),
  url('../fonts/wibee-thin-webfont.svg#RobotoMedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

.bold { font-family: 'WibeeBold', Arial, Helvetica, sans-serif; font-weight:100; }
.thin { font-family: 'WibeeThin', Arial, Helvetica, sans-serif; font-weight:100; }