/* SLIDE 04 - TARIFS */
div#tarifs {
  h3 {
    margin-bottom: 20px;
  }
  .row {
    .wi-numbers {
      .left {
        padding-right: 5px;
      }
      .right {
        padding-left: 5px;
      }
    }
    text-align: center;
    .title {
      color: @dark-blue;
      font-family: WibeeRegular, Helvetica;
      border-top: 2px solid @dark-blue;
      font-size: 2em;
      padding: 10px;
    }
    .offer {
      background-color: @dark-blue;
      min-height: 105px;
      color: #ffffff;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 20px;
      font-size: 1.8em;
      &.mini {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 10px;
      }
      &.mini-left {
        border-bottom-right-radius: 0px;
      }
      &.mini-right {
        border-bottom-left-radius: 0px;
      }
      &:hover, &:active {
        background-color: @rouche;
        cursor: pointer;
      }
      .sub {
        font-size: 0.5em;
      }
    }
  }
  .below {
    text-align: center;
    h2 {
      border: 0;
      font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
      font-weight: 100;
      font-size: 2.2em;
      color: @rouche;
      a {
        font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
        font-weight: 100;
        font-size: 2.2em;
        color: @rouche;
      }
    }
  }
}
.tarif {
  &.modal {
    .modal-backdrop {
      background-color: transparent;
    }
    .modal-content {
      border-radius: 0px;
      padding: 20px;
      color: @dark-blue;
      .btn {
        background-color: @rouche;
        color: #ffffff;
      }
    }
    .modal-dialog {
      margin: 200px auto;
      width: 1140px;
    }
  }
}
@media only screen and (max-width: 990px) {
  div#tarifs {
    .row {
      .wi-numbers {
        .left {
          padding-right: 15px;
          margin-bottom: 10px;
        }
        .right {
          padding-left: 15px;
        }
      }
      text-align: center;
      .title {
        color: @dark-blue;
        font-family: WibeeRegular, Helvetica;
        border-top: none;
        font-size: 2em;
        padding: 10px;
      }
      .offer {
        background-color: @dark-blue;
        min-height: 105px;
        color: #ffffff;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding: 20px;
        font-size: 1.8em;
        &.mini {
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          margin-bottom: 10px;
        }
        &.mini-left {
          border-bottom-right-radius: 0px;
        }
        &.mini-right {
          border-bottom-left-radius: 0px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .tarif {
    &.modal {
      .modal-dialog {
        margin: 100px auto;
        width: 100%;
      }
    }
  }
}
