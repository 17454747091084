/* simple */
.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;

  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;


}

/*
.flip-container:hover .flipper, .flip-container.hover .flipper, #flip-toggle.flip .flipper {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
    filter: FlipH;
    -ms-filter: "FlipH";
}
*/


/* START: Accommodating for IE */
.flip-container:hover .hex-back, .flip-container.hover .hex-back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container:hover .hex-front, .flip-container.hover .hex-front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

/* END: Accommodating for IE */

.flip-container, .hex-front, .hex-back {
  width: 203px; height: 194px;
}

.flipper {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 0.6s;

  -moz-transition: 0.6s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;

  transition: 0.6s;
  transform-style: preserve-3d;

  position: relative;
}

.hex-front, .hex-back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;

  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;

  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;

  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;

  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;

  transition: 0.6s;
  transform-style: preserve-3d;

  position: absolute;
  top: 0;
  left: 0;
}

.hex-front {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  background: lightgreen;
  z-index: 2;
  background: url(../images/slide02-hex.png) top left no-repeat;
}

.hex-back {
  background: lightblue;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  background: url(../images/slide02-hex-hover.png) top left no-repeat;
}

.hex-front .name {
  font-size: 2em;
  display: inline-block;
  background: rgba(33, 33, 33, 0.9);
  color: #f8f8f8;
  font-family: Courier;
  padding: 5px 10px;
  border-radius: 5px;
  bottom: 60px;
  left: 25%;
  position: absolute;
  text-shadow: 0.1em 0.1em 0.05em #333;
  display: none;

  -webkit-transform: rotate(-20deg);
  -moz-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  transform: rotate(-20deg);
}


/* vertical */
.vertical.flip-container {
  position: relative;
}

.vertical .hex-back {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.vertical.flip-container .flipper {
  -webkit-transform-origin: 100% 213.5px;
  -moz-transform-origin: 100% 213.5px;
  -ms-transform-origin: 100% 213.5px;
  transform-origin: 100% 213.5px;
}

/*
.vertical.flip-container:hover .flipper {
    -webkit-transform: rotateX(-180deg);
    -moz-transform: rotateX(-180deg);
    -ms-transform: rotateX(-180deg);
    transform: rotateX(-180deg);
}
*/

/* START: Accommodating for IE */
.vertical.flip-container:hover .hex-back, .vertical.flip-container.hover .hex-back {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

.vertical.flip-container:hover .hex-front, .vertical.flip-container.hover .hex-front {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
/* END: Accommodating for IE */