/* SLIDE 02 - EN SAVOIR PLUS */
#ensavoirplus {
  a {
    color: #0e1d59;
    text-decoration: none
  }
  .get-the-app {
    display: none;
    ul {
      li {
        margin: 0;
        padding: 0 10px 0 0;
        float: left;
        list-style: none;
      }
    }
    span {
      display: none;
      visibility: hidden;
    }
  }
  ul.hexagons {
    position: relative;
    width: 960px;
    margin: 20px auto;
    height: 600px;
    list-style: none;
    #slide02-hex-01 {
      position: absolute;
      top: 134px;
      left: 33px;
    }
    #slide02-hex-02 {
      position: absolute;
      top: 66px;
      left: 214px;
    }
    #slide02-hex-03 {
      position: absolute;
      top: 0px;
      left: 396px;
    }
    #slide02-hex-04 {
      position: absolute;
      top: 259px;
      left: 181px;
    }
    #slide02-hex-05 {
      position: absolute;
      top: 192px;
      left: 362px;
    }
    #slide02-hex-06 {
      position: absolute;
      top: 126px;
      left: 544px;
    }
    #slide02-hex-07 {
      position: absolute;
      top: 59px;
      left: 726px;
    }
    #slide02-hex-08 {
      position: absolute;
      top: 450px;
      left: 148px;
    }
    #slide02-hex-09 {
      position: absolute;
      top: 383px;
      left: 330px;
    }
    #slide02-hex-10 {
      position: absolute;
      top: 316px;
      left: 512px;
    }
    #slide02-hex-11 {
      position: absolute;
      top: 249px;
      left: 694px;
    }
    #slide02-hex-12 {
      position: absolute;
      top: 506px;
      left: 480px;
    }
    #slide02-hex-13 {
      position: absolute;
      top: 440px;
      left: 662px;
    }

    .hex-front p, .hex-back p {
      padding: 30px 30px;
      text-align: center;
      font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
      font-weight: 100;
      font-size: 1.4em;
    }
    .squeeze p {
      line-height: 1em;
    }
    .hex-front span.small {
      font-size: 16px;
      line-height: .8em;
    }
    .hex-back p {
      font-family: 'WibeeRegular', Arial, Helvetica, sans-serif;
      font-size: 1em;
      line-height: 1em;
      color: rgba(255, 255, 255, 1.00);
    }
  }

}

/* #Tablet (Portrait) - Design for a width of 768px */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  #ensavoirplus {
    ul.hexagons {
      width: 740px;
      height: 650px;
      #slide02-hex-01, #slide02-hex-07, #slide02-hex-11 {
        display: none;
      }
      #slide02-hex-02 {
        left: 114px;
      }
      #slide02-hex-03 {
        left: 296px;
      }
      #slide02-hex-04 {
        left: 81px;
      }
      #slide02-hex-05 {
        left: 262px;
      }
      #slide02-hex-06 {
        left: 444px;
      }
      #slide02-hex-08 {
        left: 48px;
      }
      #slide02-hex-09 {
        left: 230px;
      }
      #slide02-hex-10 {
        left: 412px;
      }
      #slide02-hex-12 {
        left: 380px;
      }
      #slide02-hex-13 {
        left: 562px;
      }
    }
  }

  .get-the-app {
    display: block;
    width: 140px;
    height: 50px;
    position: absolute;
    top: 32px;
    left: 562px;
    z-index: 150;
  }
}

/*  #Mobile (Portrait) - Design for a width of 320px */
@media only screen and (max-width: 767px) {
  .get-the-app {
    display: block;
    width: 160px;
    height: 150px;
    position: relative;
    top: 16px;
    margin-left: -80px;
    left: 50%;
    z-index: 420;
    padding: 20px 0px 20px 20px;
    a.appstore {
      width: 130px;
      height: 45px;
      background-image: url(../images/appstore-button.png);
      background-repeat: no-repeat;
      background-position: top left;
      display: inline-block;
    }
    a.googleplay {
      width: 130px;
      height: 45px;
      background-image: url(../images/googleplay-button.png);
      background-repeat: no-repeat;
      background-position: top left;
      display: inline-block;
    }
  }

  #ensavoirplus {
    min-height: 842px;
    ul.hexagons {
      width: 100%;
      height: 580px;
      #slide02-hex-03, #slide02-hex-04, #slide02-hex-06, #slide02-hex-07, #slide02-hex-08, #slide02-hex-09, #slide02-hex-10, #slide02-hex-11, #slide02-hex-12 {
        visibility: hidden;
        display: none;
      }
      #slide02-hex-01 {
        display: block;
        position: absolute;
        top: 10px;
        left: 50%;
        width: 203px;
        margin-left: -101px;
      }
      #slide02-hex-02 {
        display: block;
        position: absolute;
        top: 255px;
        left: 50%;
        width: 203px;
        margin-left: -101px;
      }
      #slide02-hex-05 {
        display: block;
        position: absolute;
        top: 500px;
        left: 50%;
        width: 203px;
        margin-left: -101px;
      }
      .hex-front, .hex-back {
        p {
          padding: 60px 30px;
        }
      }
    }
  }
}
