#rejoignez-nous {
  position: relative;
  .ici {
    position: absolute;
    z-index: 100;
    right: 10px;
    top: -70px;
    p {
      position: absolute;
      top: 59px;
      right: 78px;
      color: #ffffff;
      font-size: 3em;
      font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
    }
  }
  #map {
    margin-top: 40px;
    height: 500px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 960px) {

}

@media only screen and (max-width: 767px) {
  #rejoignez-nous {
    .ici {
      position: relative;
      width: 200px;
      left: 50%;
      margin-left: -100px;
      margin-top: 100px;
    }
    #map {
      display: none;
    }
  }
}