/* SLIDE 01 - FONCTIONNEMENT */
#fonctionnement {
  .col-md-4 {
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    min-height:300px;
    padding: 25px;
    &:nth-child(1) {
      background-image: url('../images/step1.png');
    }
    &:nth-child(2) {
      background-image: url('../images/step2.png');
    }
    &:nth-child(3) {
      background-image: url('../images/step3.png');
    }
    .body {
      color: #0e1d59;
      width: 90%;
      margin: 10px auto;
      font-size: 1.2em;
    }
    .header {
      color: #0e1d59;
      font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
      font-size:2.1em;
      line-height: 1.2em;
      width:60%;
      margin: 10px auto;
    }
  }
}
/* #Tablet (Portrait) - Design for a width of 768px */
@media only screen and (min-width: 768px) and (max-width: 959px) {
  #fonctionnement {
  }
}

/*  #Mobile (Portrait) - Design for a width of 320px */
@media only screen and (max-width: 767px) {
  #fonctionnement {
    .col-md-4 {
      min-height: 20px;
      padding: 40px;
      .body {
        width: 60%;
      }
      .header {
        width:60%;
      }
    }
  }
}