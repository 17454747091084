.signup-page {
  h3 {
    color: @light-blue;
    text-align: left;
  }
  img.stepone {
    position: absolute;
    right: 10%;
  }
  img#toplogo {
    width: 70px;
    position: absolute;
    left: 50%;
    margin-top: -5px;
    margin-left: -35px;
  }
  #social-links-toolbar {
    ul {
      margin: 0 0 0 94px;
      padding: 12px 0 0 0;
    }
    li {
      margin: 0;
      padding: 0 10px 0 0;
      float: left;
      list-style: none;
    }
    li a {
      text-decoration: none;
      width: 32px;
      height: 32px;
      display: inline-block;
    }
    li a span {
      visibility: hidden;
      display: none;
    }
    li a.social-links-facebook {
      background-image: url(../images/social-links-facebook.png);
      background-position: left top;
      background-repeat: no-repeat;
    }
    li a.social-links-twitter {
      background-image: url(../images/social-links-twitter.png);
      background-position: left top;
      background-repeat: no-repeat;
    }
    li a.social-links-linkedin {
      background-image: url(../images/social-links-linkedin.png);
      background-position: left top;
      background-repeat: no-repeat;
    }
    li a.social-links-email {
      background-image: url(../images/social-links-email.png);
      background-position: left top;
      background-repeat: no-repeat;
    }
  }
  #language-selector {
    position: absolute;
    top: 21px;
    ul {
      display: inline;
    }
    li {
      margin: -7px 0 0 0;
      padding: 0 10px 0 0;
      float: left;
      list-style: none;
    }
    li a {
      text-decoration: none;
      color: #ffffff;
      display: inline-block;
      &:hover {
        text-decoration: underline;
        color: @rouche;
      }
    }
  }
  .signup {
    background: url('../images/user-register-form-bkg.png');
    background-repeat: no-repeat;
    background-position-x: 44%;
    position: relative;
    .welcome {
      background: url(../images/form-profile-hex-ID.png);
      background-repeat: no-repeat;
      position: absolute;
      width: 208px;
      height: 198px;
      left: 0px;
      font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
      color: #ffffff;
      font-weight: 100;
      font-size: 1.5em;
      padding: 45px 40px 50px 50px;
      line-height: 1em;
    }
    .tarif-choice {
      background: url(../images/form-profile-hex-01.png);
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      top: 200px;
      width: 208px;
      height: 198px;
      padding: 40px 0px 0px 10px;
      text-align: center;
      p {
        color: #ffffff;
        font-weight: 100;
        font-size: 1.5em;
        font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
        #tarif-info {
          margin-top:5px;
        }
      }
      select {
        height: 30px;
        width: 90%;
        margin: 5px;
        display: block;
      }
    }
    .ambassador {
      background: url(../images/form-profile-hex-ID.png);
      background-repeat: no-repeat;
      position: absolute;
      top: 500px;
      width: 208px;
      height: 198px;
      right: 0px;
      font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
      color: #ffffff;
      font-weight: 100;
      font-size: 1.5em;
      padding: 60px 40px 50px 50px;
      line-height: 1em;
      &:hover {
        cursor: pointer;
      }
    }
    #owncar, #carmodel {
      display:none;
      width: 70%;
    }
    .form {
      width: 500px;
      min-height: 750px;
      margin: 80px 100% 0 40%;
      input[type=text], input[type=email], input[type=password] {
        width: 70%;
        margin: 5px;
        display: block;
      }
      input#finder {
        width: 100%;
      }
      label {
        display: block;
        font-weight: normal;
        color: #ffffff;
        font-size: 12px;
      }
      input[type=checkbox] {
        margin: 5px;
      }
      .tabs {
        width: 70%;
        input[name=homepostcode], input[name=invoicepostcode] {
          width: 20%;
          display: inline-block;
        }
        input[name=homecity], input[name=invoicecity] {
          width: 37%;
          display: inline-block;
        }
      }
    }
  }
}

.modal {
  .bg-success, .bg-danger {
    padding: 20px;
  }
}

@media only screen and (max-width: 870px) {
  .signup-page {
    img.stepone {
      position: relative;
      width: 262px;
      left: 50%;
      margin-left: -131px;
      padding-bottom: 40px;
    }
    img#toplogo {
      width: 70px;
      position: absolute;
      right: 20px;
      left: auto;
      margin: 0;
    }
    #social-links-toolbar {
      ul {
        padding: 12px 0 0 0;
      }
    }
    background: #e63067;
    .signup {
      background: #e63067;
      background-image: none;
      .welcome {
        position: relative;
        width: 208px;
        height: 198px;
        left: 50%;
        font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
        color: #ffffff;
        font-weight: 100;
        font-size: 1.5em;
        padding: 35px 40px 50px 45px;
        line-height: 1em;
        margin-left: -104px;
        margin-top: -20px;
      }
      .tarif-choice {
        background: none;
        background-size: contain;
        background-repeat: no-repeat;
        position: static;
        left: 0px;
        top: 200px;
        width: auto;
        height: auto;
        padding: 0px;
        p {
          display: none;
        }
        select {
          height: 30px;
          width: 100%;
          margin: 5px;
          display: block;
        }
      }
      .form {
        width: 90%;
        margin: 20px auto;
        background: #e63067;
        background-image: none;
        input[type=text], input[type=password], input[type=email] {
          width: 100%;
          margin: 5px;
          display: inline-block;
        }
        .tabs {
          width: 100%;
        }
      }
    }
  }
}