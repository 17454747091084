.navbar-default {
  background-color: #55a4b1;
  border: none;
  border-radius: 0;
  color: #ffffff;
  margin-bottom: 40px;
  .logged-in.navbar-nav {
    margin-top: -16px;
    background-color: #0790a2;
    li {
      a {
        color: #ffffff;
        &.dropdown-toggle {
          padding-bottom: 10px;
          padding-top: 20px;
        }
      }
    }
    .dropdown-menu {
      background-color: #0790a2;
      li {
        a {
          &:hover {
            color: #000000;
          }
        }
      }
    }
  }
  .top-nav {
    height: 50px;
    .navbar-form {
      margin-top: -6px;
      .form-group {
        position: relative;
        z-index: 150;
        a.inscription {
          color: #ffffff;
          margin-right: 10px;
          font-weight: bold;
        }
        .form-control {
          height: 26px;
        }
      }
      .btn {
        height: 26px;
        line-height: 1;
        color: #fff;
        background-color: #FF5D88;
        border-color: #FF5D88;
      }
    }
    #social-links-toolbar {
      ul {
        margin: 0 0 0 94px;
        padding: 16px 0 0 0;
      }
      li {
        margin: -7px 0 0 0;
        padding: 0 10px 0 0;
        float: left;
        list-style: none;
      }
      li a {
        text-decoration: none;
        width: 32px;
        height: 32px;
        display: inline-block;
      }
      li a span {
        visibility: hidden;
        display: none;
      }
      li a.social-links-facebook {
        background-image: url(../images/social-links-facebook.png);
        background-position: left top;
        background-repeat: no-repeat;
      }
      li a.social-links-twitter {
        background-image: url(../images/social-links-twitter.png);
        background-position: left top;
        background-repeat: no-repeat;
      }
      li a.social-links-linkedin {
        background-image: url(../images/social-links-linkedin.png);
        background-position: left top;
        background-repeat: no-repeat;
      }
      li a.social-links-email {
        background-image: url(../images/social-links-email.png);
        background-position: left top;
        background-repeat: no-repeat;
      }
    }
    #language-selector {
      position: absolute;
      top: 21px;
      ul {
        display: inline;
      }
      li {
        margin: -7px 0 0 0;
        padding: 0 10px 0 0;
        float: left;
        list-style: none;
      }
      li a {
        text-decoration: none;
        color: #ffffff;
        display: inline-block;
        &:hover {
          text-decoration: underline;
          color: @rouche;
        }
      }
    }
  }

  .bottom-nav {
    background-color: #f9f5f0;
    font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
    .container {
    }
    .navbar-nav,
    .navbar-nav > li {
      float: none;
      display: inline-block;
      *display: inline; /* ie7 fix */
      *zoom: 1; /* hasLayout ie7 trigger */
      vertical-align: top;
      margin: 0 30px;
      a {
        font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
        font-weight: 100;
        font-size: 1.4em;
        color: #0d4070;
      }
      a:hover, &.active a {
        color: #ED1548;
        background: none;
      }
      &.hex-bg div {
        background-image: url(../images/hex-bg.png);
        width: 231px;
        height: 128px;
        top: 0px;
        left: -40px;
        position: absolute;
      }
      &.hex-bg a {
        color: #ffffff;
      }
      &.hex-bg a:hover, &.hex-bg.active a {
        color: #0d4070;
      }
    }

    .navbar-collapse {
      text-align: center;
    }
  }
}

#scrollToTop {
  position: fixed;
  a {
    position: relative;
    left: 20px;
    background: url(../images/scroll-to-top.png) no-repeat top left;
    display: inline-block;
    width: 60px;
    height: 60px;
    text-indent: -50000px;
    z-index: 440;
  }
  a:hover {
    background: url(../images/scroll-to-top-hover.png) no-repeat top left;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: fixed;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .navbar-default {
    .top-nav {
      #social-links-toolbar {
        ul {
          margin: 0 0 0 -30px;
        }
      }
    }
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: fixed;
  }

}

@media only screen and (max-width: 767px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: absolute;
  }
    .navbar-default {
    .bottom-nav {
      img#logo-mobile {
        position: absolute;
        width: 120px;
        left: 50%;
        margin-left: -60px;
        margin-top: 0px;
      }
      .navbar-collapse {
        background: #ffffff;
      }
      .navbar-nav {
        li {
          display: block;
          &.hex-bg {
            div {
              display: none;
            }
          }
        }
      }
    }
    .top-nav {
      #social-links-toolbar {
        ul {
          width: 170px;
          padding: 16px 0 0 0;
          left: 50%;
          margin-left: -85px;
          position: absolute;
        }
      }
    }
  }
}