body {
  padding-top: 90px;
  background-color: #f9f5f0;
  overflow-x: hidden;
}
.modal {
  overflow-y: auto;
}

.modal-open {
  overflow: auto;
}

h2, h3 {
  width: 100%;
  padding: .1em 0;
  color: #55a4b1;
  font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
  font-weight: 100;
  border-bottom: 1px solid #55a4b1;
  border-top: 1px solid #55a4b1;
  font-size: 2.6em;
  text-align: center;
}

h3 {
  font-family: 'WibeeRegular', Arial, Helvetica, sans-serif;
  border-bottom: none;
  border-top: none;
  font-size: 2.3em;
}

img {
  max-width: 100%;
}

div.slider {
  position: relative;
  text-align: center;
  overflow: hidden;
  p.slogan {
    position: absolute;
    color: #ffffff;
    font-size: 3em;
    font-family: 'WibeeBold', Arial, Helvetica, sans-serif;
    top: 50px;
    left: 50px;
  }
  img.bg {
    width: 100%;
  }
  img.anim {
    position: absolute;
    &#one {
      left: -4000px;
      top: 5%;
    }
    &#two {
      left: 0;
      top: -4000px;
    }
    &#three {
      top: -4000px;
      right: -4000px;
    }
    &#four {
      right: -4000px;
      bottom: 0;
    }
  }
}

/* #Tablet (Portrait) - Design for a width of 768px */
@media only screen and (min-width: 768px) and (max-width: 959px) {

}

/*  #Mobile (Portrait) - Design for a width of 320px */
@media only screen and (max-width: 767px) {
  img.anim {
    display: none;
  }

}