@media only screen
and (max-width: 1024px) {
  .navbar-default {
    .bottom-nav {
      .navbar-nav {
        li {
          margin: 0;
          &.hex-bg {
            a {
              color: #0d4070;
              &:hover {
                color: #ED1548;
              }
            }
            div {
              background: none;
            }
          }
        }
      }
    }
  }
  h2 {
    margin: 10px auto;
  }
}